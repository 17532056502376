<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ name }}</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card>
                        <v-toolbar color="red white--text" dark flat dense>
                        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                        <v-toolbar-title>
                            Delete Dynamic DNS Host
                        </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>

                            <template v-if="account">
                            <p class="text-overline mb-0">Account name</p>
                            <p class="mb-0 pb-0">
                                <span class="font-weight-bold">{{ account.name }}</span>
                            </p>
                            </template>

                            <template v-if="dynamicDnsRecord">
                            <p class="text-overline mb-0 mt-4">Dynamic DNS Host</p>
                            <p class="mb-0 pb-0">
                                <span class="font-weight-bold">{{ dynamicDnsRecord.host }}.{{ dynamicDnsRecord.domain }}</span>
                            </p>
                            </template>

                            <p class="text-overline mb-0 mt-4">Confirmation</p>

                            <p>Tap on the button below to delete this Dynamic DNS host. The corresponding records will be unpublished from DNS. Your host will become unreachable.</p>
                            <p>This action is not reversible -- you can add a new Dynamic DNS host later but it will have a different name.</p>

                            <v-alert type="warning" border="left" dense>
                                <template #prepend>
                                    <font-awesome-icon fixed-width :icon="['fas', 'exclamation-triangle']" class="white--text text-body-1"/>
                                </template>
                                <span class="font-weight-bold ml-2">This action is not reversible.</span>
                                <!-- <font-awesome-icon fixed-width :icon="['fas', 'info-circle']" class="white--text text-body-1 ml-2"/> -->
                            </v-alert>

                            <v-checkbox color="amber darken-2" v-model="deleteConfirmation1" label="I want to delete the Dynamic DNS host"></v-checkbox>
                            <v-checkbox color="amber darken-2" v-model="deleteConfirmation2" label="I understand this will also unpublish corresponding DNS records for this host, and that my website or other network services may become unreachable"></v-checkbox>
                            <v-checkbox color="amber darken-2" v-model="deleteConfirmation3" label="I understand this action is not reversible."></v-checkbox>
                            <v-btn color="red" class="white--text" @click="deleteHost" :disabled="!isDeleteHostConfirmed">Delete Host</v-btn>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        account: null,
        domainList: null,
        error: null,
        deleteConfirmation1: false,
        deleteConfirmation2: false,
        deleteConfirmation3: false,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isDeleteHostConfirmed() {
            return this.deleteConfirmation1 && this.deleteConfirmation2 && this.deleteConfirmation3;
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadDynamicDnsRecord() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadDynamicDnsRecord: true });
                const response = await this.$client.account(this.$route.params.accountId).dynamicDnsRecord.search({ domain: this.$route.query.domain, host: this.$route.query.host });
                if (Array.isArray(response.list) && response.list.length > 0) {
                    this.dynamicDnsRecord = response.list[0];
                } else {
                    this.$router.replace({ name: 'account-search-dynamic-dns-record', params: { accountId: this.$route.params.accountId } });
                }
            } catch (err) {
                console.error('failed to load dynamic dns record', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadDynamicDnsRecord: false });
            }
        },
        async deleteHost() {
            try {
                this.$store.commit('loading', { deleteHost: true });
                const response = await this.$client.account(this.$route.params.accountId).dynamicDnsRecord.delete({ domain: this.$route.query.domain, host: this.$route.query.host });
                if (response?.isDeleted) {
                    this.$bus.$emit('snackbar', { type: 'info', headline: 'Deleted' });
                    this.$router.push({ name: 'account-search-dynamic-dns-record', params: { account: this.$route.params.accountId } });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete item', message: 'Please contact customer support' });
                }
            } catch (err) {
                console.error('failed to delete item', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete item', message: 'Please contact customer support' });
            } finally {
                this.$store.commit('loading', { deleteHost: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        if (this.$route.query.domain && this.$route.query.host) {
            this.loadDynamicDnsRecord();
        } else {
            this.$router.replace({ name: 'account-search-dynamic-dns-record', params: { accountId: this.$route.params.accountId } });
        }
    },
};
</script>
